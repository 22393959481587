<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>

    <agenda-component
      v-if="dialogAgenda"
      :visible="dialogAgenda"
      :cita="editedItem"
      :cliente="editedItem.cliente"
      @close="dialogAgenda = false"
    />

    <base-material-card
      color="indigo"
      icon="mdi-file-cabinet"
      title="Listado de expedientes"
      class="px-5 py-3"
    >
      <v-dialog
        v-model="dialogTimeLine"
        scrollable
        persistent
        max-width="80%"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5 font-weight-bold mt-3">Historial expediente No. {{ idExp }} || Cliente: {{ clienteName }}</span>
          </v-card-title>

          <v-card-text>
            <v-timeline dense>
              <v-timeline-item
                v-for="(evento) in timeline"
                :key="evento.id"
                color="indigo"
                small
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="2"
                  >
                    <strong v-text="evento.fecha" />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <strong v-text="evento.evento" />
                    <div
                      class="text-text-caption"
                      v-text="evento.descripcion"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <strong v-text="evento.usuarioName" />
                  </v-col>
                </v-row>
                <hr>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="closeTimeLine"
            >
              {{ $t("close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogGstExp"
        scrollable
        persistent
        max-width="80%"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5 font-weight-bold mt-3">Gestionar expediente No. {{ idExp }} - Cliente: {{ clienteName }}</span>
          </v-card-title>

          <v-card-text>
            <v-form
              ref="formGstExp"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <v-container>
                <v-row v-if="editedItem.citas[0] != undefined">
                  <v-col
                    cols="12"
                    style="text-align: right"
                  >
                    <b>Cita: </b>{{ editedItem.citas[0].inicio + " - " + editedItem.citas[0].fin }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-select
                      v-model="editedItem.encargadonew"
                      :rules="[v => !!v || 'El oficial asignado es requerido']"
                      :items="empleados"
                      item-text="nombreCompleto"
                      item-value="id"
                      label="Asignar a"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-select
                      v-model="editedItem.estadonew"
                      :rules="[v => !!v || 'El oficial asignado es requerido']"
                      :items="estados"
                      item-text="nombre"
                      item-value="id"
                      return-object
                      label="Pasar a estado"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <div class="h5 pt-3">
                      <span class="font-weight-black">Procedimiento : </span>
                      <span>{{ procedimientoName }}</span>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="2"
                  >
                    <v-btn
                      v-show="orden === 3 && cita_confirmada !== 'CONFIRMADA'"
                      block
                      class="ma-2 white--text"
                      @click="showAgenda"
                    >
                      Crear cita
                      <v-icon
                        right
                        dark
                      >
                        mdi-calendar-month
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-text-field
                      v-if="orden > 7"
                      v-model="editedItem.protocolo"
                      :rules="[v => !!v || 'El protocolo es requerido']"
                      label="Protocolo"
                    />
                  </v-col>
                </v-row>

                <v-subheader
                  class="text-h5 font-weight-bold mt-2"
                >
                  Clientes en proceso ({{ ocupacion }}/{{ cupos }})
                  <v-dialog
                    v-model="dialogAddCliente"
                    persistent
                    scrollable
                    max-width="50%"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-show="ocupacion < cupos"
                        class="ma-2 white--text"
                        v-on="on"
                      >
                        Agregar cliente
                        <v-icon
                          right
                          dark
                        >
                          mdi-account-tie
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5 font-weight-bold mt-3">Agregar clientes al expediente</span>
                      </v-card-title>

                      <v-card-text>
                        <v-form
                          ref="formAddCliente"
                          v-model="validAddCli"
                          :lazy-validation="lazy"
                        >
                          <v-container>
                            <v-row>
                              <v-col
                                cols="12"
                                sm="6"
                                md="12"
                              >
                                <v-select
                                  v-model="addCliente"
                                  :rules="[v => !!v || 'El cliente es requerido']"
                                  :items="clientes"
                                  item-text="nombre"
                                  item-value="id"
                                  return-object
                                  label="Clientes"
                                />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="closeAddCliente"
                        >
                          {{ $t("close") }}
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="saveCliente"
                        >
                          {{ $t("save") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-subheader>

                <v-divider />

                <v-row>
                  <v-col
                    v-for="usuario in requerimientos"
                    :key="usuario.id"
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-card
                      class="mx-auto"
                    >
                      <v-toolbar
                        dense
                        dark
                        color="indigo"
                      >
                        <v-toolbar-title v-text="usuario.nombre" />
                        <v-spacer />
                        <v-btn
                          v-show="!usuario.isCliente"
                          icon
                          @click="removeCliente(usuario.id)"
                        >
                          <v-icon>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-toolbar>

                      <v-list subheader>
                        <v-subheader>Documentos requeridos</v-subheader>

                        <v-list-item
                          v-for="item in usuario.documentos"
                          :key="item.id"
                        >
                          <v-list-item-avatar>
                            <v-icon
                              v-if="item.file"
                              color="info"
                              @click="descargarRequerimiento(item.idfile, item.fileName, item.storage)"
                            >
                              mdi-download
                            </v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-text="item.nombre" />
                          </v-list-item-content>

                          <v-list-item-icon>
                            <v-icon
                              v-if="item.cumple"
                              color="success"
                            >
                              mdi-check-circle
                            </v-icon>
                            <v-icon
                              v-else
                              color="error"
                            >
                              mdi-close-circle
                            </v-icon>
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>

                <v-subheader
                  class="text-h5 font-weight-bold mt-2"
                >
                  Aportar documentos al expediente
                </v-subheader>

                <v-divider />

                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="5"
                  >
                    <v-select
                      v-model="editedItem.documentos.tipo"
                      :items="tipos"
                      item-text="nombre"
                      item-value="id"
                      return-object
                      prepend-icon="mdi-format-list-bulleted-type"
                      label="Tipo de documento"
                    />
                    <v-file-input
                      v-model="editedItem.documentos.file"
                      label="Seleccione documento"
                    />
                    <v-select
                      v-model="editedItem.usuarioCarga"
                      :items="editedItem.usuarios"
                      item-text="nombre"
                      item-value="id"
                      return-object
                      clearable
                      prepend-icon="mdi-format-list-bulleted-type"
                      label="Propietarioooo"
                    />
                    <v-btn
                      block
                      class="ma-2 white--text"
                      @click="cargarDocumento"
                    >
                      Cargar documento
                      <v-icon
                        right
                        dark
                      >
                        mdi-cloud-upload
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="7"
                  >
                    <v-card
                      max-width="100%"
                      min-height="100%"
                      max-height="250px"
                      class="mx-auto overflow-y-auto"
                    >
                      <v-toolbar
                        dark
                        dense
                      >
                        <v-toolbar-title>Documentos cargados</v-toolbar-title>

                        <v-spacer />

                        <v-sheet
                          dark
                          class="text-text-caption"
                        >
                          Desplaza arriba/abajo para ver más documentos
                        </v-sheet>
                      </v-toolbar>

                      <v-list
                        v-if="editedItem.documentos.length > 0"
                        two-line
                      >
                        <v-list-item
                          v-for="(item, i) in editedItem.documentos"
                          :key="i"
                          three-line
                        >
                          <v-list-item-avatar>
                            <v-btn
                              icon
                              color="info"
                              @click="descargarRequerimiento(item.id, item.fileName, 'expedientes')"
                            >
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title v-text="item.fileName" />
                            <v-list-item-subtitle
                              class="text--primary"
                            >
                              <span
                                class="font-weight-bold"
                              >
                                Tipo de documento:
                              </span>
                              {{ item.tipo }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle
                              v-show="item.usuarioName"
                              class="text--primary"
                            >
                              <span
                                class="font-weight-bold"
                              >
                                Propietario:
                              </span>
                              {{ item.usuarioName }}
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-btn icon>
                              <v-icon
                                color="error"
                                @click="removeFile(i, item)"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-card-text v-else>
                        <p
                          class="text-center text-h3 mt-5 grey--text"
                        >
                          Aún no tenemos documentos cargados.
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="closeGstExp"
            >
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="saveGstExp"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-data-table
        :headers="headers"
        :items="tableFilters()"
        class="elevation-1"
      >
        <template v-slot:header.generalName>
          <v-select
            v-model="filterExpediente"
            attach
            :items="modules.map(function (item) {
              return {
                text: item.text,
                value: item.value
              }
            })"
            label="Situación"
            style="width:100px"
          />
        </template>
        <template v-slot:item="{ item }">
          <tr
            v-if="item.estado != undefined && item.estado != null && item.estado.color != null"
            :style="{ 'background-color': item.estado.color, 'color': 'white' }"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.clienteName }}</td>
            <td>{{ item.procedimientoName }}</td>
            <td>{{ item.estadoName }}</td>
            <td>{{ item.oficialName }}</td>
            <td>{{ item.generalName }}</td>
            <td>{{ item.protocolo }}</td>
            <td>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.generalName == 'Activo'"
                    small
                    class="mr-2"
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                    @click="gstExpediente(item)"
                  >
                    mdi-folder-edit-outline
                  </v-icon>
                </template>
                <span>Gestionar expediente</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                    @click="verTimeline(item)"
                  >
                    mdi-timeline-clock-outline
                  </v-icon>
                </template>
                <span>Ver historial del expediente</span>
              </v-tooltip>
              <v-icon
                small
                class="mr-2"
                color="white"
                @click="editItem(item)"
              >
                mdi-pencil-outline
              </v-icon>
            </td>
          </tr>
          <tr v-else>
            <td>{{ item.id }}</td>
            <td>{{ item.clienteName }}</td>
            <td>{{ item.procedimientoName }}</td>
            <td>{{ item.estadoName }}</td>
            <td>{{ item.oficialName }}</td>
            <td>{{ item.generalName }}</td>
            <td>{{ item.protocolo }}</td>
            <td>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.generalName == 'Activo'"
                    small
                    class="mr-2"
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                    @click="gstExpediente(item)"
                  >
                    mdi-folder-edit-outline
                  </v-icon>
                </template>
                <span>Gestionar expediente</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                    @click="verTimeline(item)"
                  >
                    mdi-timeline-clock-outline
                  </v-icon>
                </template>
                <span>Ver historial del expediente</span>
              </v-tooltip>
              <v-icon
                small
                class="mr-2"
                color="success"
                @click="editItem(item)"
              >
                mdi-pencil-outline
              </v-icon>
            </td>
          </tr>
        </template>
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-spacer />
            <v-dialog
              v-model="dialog"
              persistent
              scrollable
              max-width="80%"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  fab
                  dark
                  class="mb-2"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5 font-weight-bold mt-3">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                  >
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-select
                            v-model="editedItem.oficial"
                            :rules="[v => !!v || 'El oficial asignado es requerido']"
                            :items="empleados"
                            item-text="nombreCompleto"
                            item-value="id"
                            label="Oficial asignado"
                            @input="setNombreOficial"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-select
                            v-model="editedItem.procedimiento"
                            :rules="[v => !!v || 'El procedimiento es requerido']"
                            :items="procedimientos"
                            item-text="nombre"
                            item-value="id"
                            return-object
                            label="Procedimiento"
                            @input="setNombreProcedimiento"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-select
                            v-model="editedItem.cliente"
                            :rules="[v => !!v || 'El cliente es requerido']"
                            :items="clientes"
                            item-text="nombre"
                            item-value="id"
                            label="Cliente"
                            @input="setNombreCliente"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-select
                            v-model="editedItem.estado"
                            :rules="[v => !!v || 'El Estado es requerido']"
                            :items="estados"
                            item-text="nombre"
                            item-value="id"
                            return-object
                            label="Estado expediente"
                            @input="setNombreEstado"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-select
                            v-model="editedItem.general"
                            :rules="[v => !!v || 'La situación del expediente es requerida']"
                            :items="estadoGeneral"
                            item-text="nombre"
                            item-value="value"
                            label="Situación expediente"
                            @input="setNombreSituacion"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="2"
                        >
                          <v-text-field
                            v-model="editedItem.participantes"
                            :rules="[v => !!v || 'El número de participantes es requerido']"
                            label="Participantes"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="12"
                        >
                          <v-textarea
                            v-model="editedItem.observaciones"
                            name="input-7-1"
                            label="Observaciones"
                            auto-grow
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    {{ $t("close") }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                  >
                    {{ $t("save") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initialize"
          >
            Recargar
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import Empleados from '@/services/api/Empleados'
  import ExpedientesApi from '@/services/api/Expedientes'
  import ProcedimientosApi from '@/services/api/Procedimientos'
  import ClientesApi from '@/services/api/Clientes'
  import EstadosApi from '@/services/api/MaestrosEstadoExpediente'
  import MaestrosTipoDocApi from '@/services/api/MaestrosTipoDocumento'
  import EventBus from '../../../../bus'

  export default {
    name: 'AdminExpedientes',

    components: {
      AgendaComponent: () => import('@/views/dashboard/component/Agenda'),
    },

    data () {
      return {
        idExp: 0,
        filterExpediente: 'all',
        modules: [
          {
            text: 'All',
            value: 'all',
          },
          {
            text: 'Activo',
            value: 'Activo',
          },
          {
            text: 'Inactivo',
            value: 'Inactivo',
          },
        ],
        clienteName: '',
        oficialName: '',
        estadoName: '',
        procedimientoName: '',
        valid: true,
        validAddCli: true,
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        dialog: false,
        dialogGstExp: false,
        dialogTimeLine: false,
        dialogAddCliente: false,
        dialogAgenda: false,
        expedientes: [],
        timeline: [],
        requerimientos: [],
        editedIndex: -1,
        lazy: false,
        clientes: [],
        empleados: [],
        procedimientos: [],
        estados: [],
        tipos: [],
        addCliente: '',
        finalizado: false,
        orden: 0,
        cita_confirmada: '',
        estadoGeneral: [
          { value: 'A', nombre: 'Activo' },
          { value: 'I', nombre: 'Inactivo' },
        ],
        headers: [
          {
            text: 'N°. Exp',
            align: 'left',
            sortable: false,
            value: 'id',
          },
          {
            text: 'Cliente',
            align: 'left',
            sortable: false,
            value: 'clienteName',
          },
          {
            text: 'Procedimiento',
            align: 'left',
            sortable: false,
            value: 'procedimientoName',
          },
          {
            text: 'Estado',
            align: 'left',
            sortable: false,
            value: 'estadoName',
          },
          {
            text: 'Oficial',
            align: 'left',
            sortable: false,
            value: 'oficialName',
          },
          {
            text: 'Situación',
            align: 'left',
            sortable: false,
            value: 'generalName',
          },
          {
            text: 'Protocolo',
            align: 'left',
            sortable: false,
            value: 'protocolo',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
        editedItem: {
          id: '',
          citas: '',
          cliente: '',
          clienteName: '',
          procedimiento: '',
          procedimientoName: '',
          estado: '',
          estadoName: '',
          oficial: '',
          oficialName: '',
          observaciones: '',
          general: 'A',
          generalName: 'Activo',
          documentos: [],
          encargadonew: null,
          estadonew: null,
          usuarios: [],
          usuarioCarga: null,
          participantes: null,
          protocolo: null,
        },
        defaultItem: {
          id: '',
          citas: '',
          cliente: '',
          clienteName: '',
          procedimiento: '',
          procedimientoName: '',
          estado: '',
          estadoName: '',
          oficial: '',
          oficialName: '',
          observaciones: '',
          general: 'A',
          generalName: 'Activo',
          documentos: [],
          encargadonew: null,
          estadonew: null,
          usuarios: [],
          usuarioCarga: null,
          participantes: 1,
          protocolo: null,
        },
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo expediente' : 'Modificar expediente'
      },
      cupos () {
        return this.editedItem.participantes
      },
      ocupacion () {
        return this.requerimientos.length
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogAddCliente (val) {
        val || this.closeAddCliente()
      },
      filterExpediente: function (newValue, oldValue) {
        this.tableFilters()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      fetchOficiales () {
        Empleados.getAllEmpleados()
          .then(empleados => {
            this.empleados = empleados
          })
          .catch(error => console.log(error))
      },
      fetchExpedientes () {
        ExpedientesApi.getAllExpedientes()
          .then(expedientes => {
            this.expedientes = expedientes
            this.tableFilters()
          })
          .catch(error => console.log(error))
      },
      fetchProcedimientos () {
        ProcedimientosApi.getAllProcedimientos()
          .then(procedimientos => {
            this.procedimientos = procedimientos
          })
          .catch(error => console.log(error))
      },
      fetchClientes () {
        ClientesApi.getAllClientesOnlyName()
          .then(clientes => {
            this.usuarios = clientes
          })
          .catch(error => console.log(error))
      },
      fetchEstados () {
        EstadosApi.getAllEstadoExpediente()
          .then(estados => {
            this.estados = estados
          })
          .catch(error => console.log(error))
      },
      fetchTipoDoc () {
        MaestrosTipoDocApi.getAllTipoDoc()
          .then(tipos => {
            this.tipos = tipos
          })
          .catch(error => console.log(error))
      },
      loadUsuarios (id) {
        ExpedientesApi.getRequerimientos(id)
          .then(requerimientos => {
            this.requerimientos = requerimientos
          })
          .catch(error => console.log(error))
      },
      expedienteFinalizado (id) {
        ExpedientesApi.isFinalizado(id)
          .then(finalizado => {
            this.finalizado = finalizado.finalizado
          })
          .catch(error => console.log(error))
      },
      initialize () {
        this.fetchExpedientes()
        this.fetchOficiales()
        this.fetchProcedimientos()
        this.fetchClientes()
        this.fetchEstados()
        EventBus.$on('update-date', () => {
          this.getExpedienteById(this.editedItem)
        })
      },

      save () {
        if (this.$refs.form.validate()) {
          if (this.editedIndex > -1) {
            const updExpediente = this.editedItem
            const indexItem = this.editedIndex
            ExpedientesApi.updateExpedientes(this.editedItem.id, this.editedItem)
              .then(response => {
                if (typeof response.error_dni !== 'undefined') {
                  this.text = response.error_dni
                  this.colorSnak = 'error'
                  this.snackbar = true
                } else {
                  Object.assign(this.expedientes[indexItem], updExpediente)
                  this.text = 'Registro modificado correctamente'
                  this.snackbar = true
                  this.colorSnak = 'success'
                }
              })
              .catch(error => {
                console.log(error)
                this.text = 'El registro no puede ser modificado en este momento'
                this.colorSnak = 'error'
                this.snackbar = true
              })
          } else {
            const addExpediente = this.editedItem
            ExpedientesApi.addExpedientes(this.editedItem)
              .then(response => {
                if (typeof response.error_dni !== 'undefined') {
                  this.text = response.error_dni
                  this.colorSnak = 'error'
                  this.snackbar = true
                } else {
                  addExpediente.id = response.id
                  addExpediente.estadoName = response.estado
                  this.expedientes.push(addExpediente)
                  this.text = 'Registro creado correctamente'
                  this.colorSnak = 'success'
                  this.snackbar = true
                  this.editedIndex = -1
                  this.close()
                }
              })
              .catch(error => {
                console.log(error)
                this.text = 'El registro no puede ser creado en este momento'
                this.colorSnak = 'error'
                this.snackbar = true
              })
          }
        }
      },

      editItem (item) {
        this.editedIndex = this.expedientes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        this.getDocumentosVencidos(item)
      },

      deleteItem (item) {
        const index = this.expedientes.indexOf(item)
        confirm('Realmente desea eliminar este registro?') && this.expedientes.splice(index, 1)
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeAddCliente () {
        this.dialogAddCliente = false
      },

      gstExpediente (item) {
        ExpedientesApi.getRequerimientos(item.id)
          .then(requerimientos => {
            this.requerimientos = requerimientos
            this.editedIndex = this.expedientes.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.idExp = item.id
            this.clienteName = item.clienteName
            this.estadoName = item.estado.nombre
            this.oficialName = item.oficialName
            this.procedimientoName = item.procedimientoName
            this.editedItem.encargadonew = item.encargado
            this.editedItem.estadonew = item.estado
            this.dialogGstExp = true
            this.orden = item.estado.orden
            this.getExpedienteById(item)

            this.expedienteFinalizado(item.id)
            this.fetchTipoDoc()
            this.getDocumentosVencidos(item)
          })
          .catch(error => console.log(error))
      },

      getDocumentosVencidos (item) {
        var Hoy = new Date()
        var AnyoHoy = Hoy.getFullYear()
        var MesHoy = Hoy.getMonth()
        var DiaHoy = Hoy.getDate()
        var count = 0
        item.usuarios.forEach(element => {
          element.documentos.forEach(document => {
            var fecha = document.expira.split('/')
            var fechaEval = new Date(fecha[2], fecha[1] - 1, fecha[0])

            var AnyoFecha = fechaEval.getFullYear()
            var MesFecha = fechaEval.getMonth()
            var DiaFecha = fechaEval.getDate()

            if (AnyoHoy > AnyoFecha && count === 0) {
              this.text = 'El cliente posee documentos vencidos'
              this.snackbar = true
              this.colorSnak = 'info'
              this.timeout = -1
              count = 1
            } else {
              if (AnyoHoy === AnyoFecha && MesFecha < MesHoy && count === 0) {
                this.text = 'El cliente posee documentos vencidos'
                this.snackbar = true
                this.colorSnak = 'info'
                this.timeout = -1
                count = 1
              } else {
                if (AnyoFecha === AnyoHoy && MesFecha === MesHoy && DiaFecha < DiaHoy && count === 0) {
                  this.text = 'El cliente posee documentos vencidos'
                  this.snackbar = true
                  this.colorSnak = 'info'
                  this.timeout = -1
                  count = 1
                } else {
                  if (AnyoFecha === AnyoHoy && MesFecha === MesHoy && DiaFecha === DiaHoy && count === 0) {
                    this.text = 'El cliente posee documentos que vencen el día de hoy'
                    this.snackbar = true
                    this.colorSnak = 'info'
                    this.timeout = -1
                    count = 1
                  }
                }
              }
            }
          })
        })
        setTimeout(() => {
          this.timeout = 3000
        }, 10000)
      },

      getExpedienteById (item) {
        ExpedientesApi.getExpedientesById(item.id)
          .then(expediente => {
            this.editedItem.citas = expediente.citas
            this.cita_confirmada = (expediente.citas[0] !== undefined) ? expediente.citas[0].estado : ''
          })
      },

      cargarDocumento () {
        var value = this.editedItem.documentos.file
        if (!value) {
          this.text = 'Debes seleccionar un documento'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        var value2 = this.editedItem.documentos.tipo
        if (!value2) {
          this.text = 'Debes seleccionar un tipo de documento'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        let usuarioCarga = 0
        let usuarioCargaName = ''
        if (typeof this.editedItem.usuarioCarga === 'undefined' || !this.editedItem.usuarioCarga) {
          usuarioCarga = 0
          usuarioCargaName = ''
        } else {
          usuarioCarga = this.editedItem.usuarioCarga.usuario_id
          usuarioCargaName = this.editedItem.usuarioCarga.nombre
        }

        const formData = new FormData()
        formData.append('_file', this.editedItem.documentos.file)
        formData.append('expediente', this.editedItem.id)
        formData.append('tipo', this.editedItem.documentos.tipo.id)
        formData.append('usuarioCarga', usuarioCarga)
        formData.append('usuarioCargaName', usuarioCargaName)
        formData.append('oficial', true)

        ExpedientesApi.uploadFile(formData)
          .then(response => {
            const name = this.tipos.find(e => e.id === this.editedItem.documentos.tipo.id)
            this.editedItem.tipoName = name.nombre

            this.editedItem.documentos.push({
              id: response.id,
              fileName: this.editedItem.documentos.file.name,
              tipo: this.editedItem.tipoName,
              tipoid: value2.id,
              file: response.file,
              usuario: usuarioCarga,
              usuarioName: usuarioCargaName,
            })
            if (typeof this.editedItem.usuarioCarga !== 'undefined' && this.editedItem.usuarioCarga) {
              const usuarioCarga = this.requerimientos.find(e => e.id === this.editedItem.usuarioCarga.id)
              const req = usuarioCarga.documentos.find(e => e.id === value2.id)
              req.cumple = true
              req.file = response.file
              req.idfile = response.id
              req.fileName = this.editedItem.documentos.file.name
            }
            this.editedItem.documentos.file = null
            this.editedItem.documentos.tipo = ''
            this.editedItem.usuarioCarga = ''
            this.text = 'Documento cargado correctamente'
            this.colorSnak = 'success'
            this.snackbar = true
          })
          .catch(error => {
            console.log(error)
            this.text = 'El documento no puede ser cargado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },

      saveGstExp () {
        if (this.$refs.formGstExp.validate()) {
          ExpedientesApi.updateEstadoExpedientes(this.editedItem.id, this.editedItem)
            .then(response => {
              if (typeof response.error_dni !== 'undefined') {
                this.text = response.error_dni
                this.colorSnak = 'error'
                this.snackbar = true
              } else {
                const expediente = this.expedientes.find(e => e.id === this.editedItem.id)

                const empleado = this.empleados.find(e => e.id === this.editedItem.encargadonew)
                expediente.encargado = empleado.id
                expediente.encargadoName = empleado.nombreCompleto
                this.text = 'Registro modificado correctamente'
                this.snackbar = true
                this.colorSnak = 'success'
                this.editedIndex = -1
                this.finalizado = response.final
                if (response.message !== null) {
                  setTimeout(() => {
                    this.text = response.message
                    this.snackbar = true
                    this.colorSnak = 'info'
                    this.timeout = 5000
                    expediente.estado = this.editedItem.estado
                    expediente.estadoName = this.editedItem.estado.nombre
                  }, 5000)
                  this.timeout = 3000
                } else {
                  expediente.estado = this.editedItem.estadonew
                  expediente.estadoName = this.editedItem.estadonew.nombre
                  this.orden = this.editedItem.estadonew.orden
                }
              }
            })
            .catch(error => {
              console.log(error)
              this.text = 'El registro no puede ser modificado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
              this.editedIndex = -1
            })
        }

        // this.closeGstExp()
      },

      closeGstExp () {
        this.dialogGstExp = false
        this.finalizado = false
      },

      verTimeline (item) {
        ExpedientesApi.getTimeLine(item.id)
          .then(timeline => {
            this.timeline = timeline
            this.idExp = item.id
            this.clienteName = item.clienteName
            this.dialogTimeLine = true
          })
          .catch(error => console.log(error))
      },

      closeTimeLine () {
        this.dialogTimeLine = false
      },

      saveCliente () {
        if (this.$refs.formAddCliente.validate()) {
          const payload = {
            cliente: this.addCliente,
            expediente: this.editedItem.id,
          }
          ExpedientesApi.addCliente(payload)
            .then(response => {
              this.editedItem.usuarios = []
              this.loadUsuarios(this.editedItem.id)
              this.text = 'Registro creado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
              this.closeAddCliente()
            })
            .catch(error => {
              console.log(error)
              this.text = 'El registro no puede ser creado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },

      removeCliente (usuarioExp) {
        if (confirm('Realmente desea eliminar este registro?')) {
          ExpedientesApi.deleteUsuariosExpedientes(usuarioExp)
            .then(response => {
              this.requerimientos = []
              this.loadUsuarios(this.editedItem.id)
              this.text = 'Registro eliminado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },

      setNombreOficial (i) {
        const name = this.empleados.find(e => e.id === i)
        this.editedItem.oficialName = name.nombreCompleto
      },

      setNombreCliente (i) {
        const name = this.clientes.find(e => e.id === i)
        this.editedItem.clienteName = name.nombre
      },

      setNombreProcedimiento (i) {
        this.editedItem.procedimientoName = i.nombre
      },

      setNombreEstado (i) {
        this.editedItem.estadoName = i.nombre
      },

      setNombreSituacion (i) {
        this.editedItem.generalName = (i === 'A') ? 'Activo' : 'Inactivo'
      },

      forceFileDownload (response, nombre) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nombre)
        document.body.appendChild(link)
        link.click()
      },

      downloadFile (i, name) {
        const nombre = name
        ClientesApi.downloadFile(i)
          .then(file => {
            this.forceFileDownload(file, nombre)
          })
          .catch(error => console.log(error))
      },

      downloadFilePro (i, name) {
        const nombre = name
        ProcedimientosApi.downloadFile(i)
          .then(file => {
            this.forceFileDownload(file, nombre)
          })
          .catch(error => console.log(error))
      },

      downloadFileExp (i, name) {
        const nombre = name
        ExpedientesApi.downloadFile(i)
          .then(file => {
            this.forceFileDownload(file, nombre)
          })
          .catch(error => console.log(error))
      },

      descargarRequerimiento (i, name, storage) {
        switch (storage) {
          case 'clientes':
            this.downloadFile(i, name)
            break
          case 'procedimientos':
            this.downloadFilePro(i, name)
            break
          case 'expedientes':
            this.downloadFileExp(i, name)
            break
        }
      },

      removeFile (i, item) {
        const index = i
        const doc = item
        if (confirm('Realmente desea eliminar este registro?')) {
          ExpedientesApi.deleteFile(doc.id)
            .then(response => {
              if (doc.usuario !== 0) {
                const usuarioCarga = this.requerimientos.find(e => e.usuario === doc.usuario)
                const req = usuarioCarga.documentos.find(e => e.idfile === doc.id)
                req.cumple = false
                req.file = ''
                req.idfile = ''
                req.fileName = ''
              }
              this.editedItem.documentos.splice(index, 1)
              this.text = 'Registro eliminado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },

      showAgenda () {
        if (this.dialogAgenda) {
          this.dialogAgenda = false
        } else {
          this.dialogAgenda = true
        }
      },

      tableFilters () {
        var data = this.expedientes
        var select = this.filterExpediente
        switch (select) {
          case 'Activo' :
            data = data.filter(function (item, index) {
              return item.generalName.includes(select)
            })
            break
          case 'Inactivo':
            data = data.filter(function (item, index) {
              return item.generalName.includes(select)
            })
            break
          default:
            data = this.expedientes
            break
        }
        return data
      },

    },
  }
</script>
<style>
.v-timeline-item {
    padding-bottom: 0px !important;
}
.v-data-table__wrapper {
    overflow-y: auto !important;
}
</style>
